<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" :to="{name: 'admin.ads.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="ads"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.image_url="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClick(item)">
                        {{ item.image_url }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.is_active="{ item }">
                <v-row>
                    <v-col>
                        {{ item.is_active ? 'Show' : 'Hidden' }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>
             <template v-slot:item.actions="{ item }">
                <ad-delete-dialog @deleted="handleRemoveAd" :ad="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Ad from '../../../models/Ad';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AdDeleteDialog from "../../../components/dialogs/AdDeleteDialog";

export default {
    name: "ads-index",
    components: {AdminDashboardLayout,AdDeleteDialog},
    data: () => (
        {
            loading: false,
            ads: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Type', value: 'type_name'},
                {text: 'Path image', value: 'image_url'},
                {text: 'HTML code', value: 'html_code'},
                {text: 'Url ad', value: 'link_url'},
                {text: 'Show ad', value: 'is_active'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    ),
    methods: {
        handleClick(ad) {
            this.$router.push({name: 'admin.ads.show', params: {id: ad.id}});
        },
        handleRemoveAd(id) {
            this.ads = this.$_.filter(this.ads, ad => ad.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.ads = await Ad.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
